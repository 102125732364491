<template>
  <v-card min-height="400px" color="#273142">
    <v-card-title class="card-header">
    <h3>Widthrawal Request</h3>
  </v-card-title>

  <v-card-text class="pa-0" flat>
    <v-data-table
      :headers="headers"
      :items="transactions"
      :items-per-page="5"
      class="transparent"
      :color="ant"
    >
      <template v-slot:item.user_payment_method="{ item }">
        <span class="text--primary"> {{ item.user_payment_method.title }} </span>
        <span>{{ item.user_payment_method.info }}</span>
      </template>
      <template v-slot:item.state="{ item }">
        <v-chip :color="`${essayGreen}`" dark label small v-if="item.state==='Done'">{{ item.state}}</v-chip>
        <v-chip dark :color="`${btnFab}`" label small v-if="item.state==='Closed'">{{ item.state}}</v-chip>
      </template>
      <template v-slot:item.created="{ item }">
        <span>{{ determineDuration(item.created)  }}</span>
      </template>
      <template v-slot:item.fees="{ item }">
        <span>${{  item.fees }}</span>
      </template>
    </v-data-table>
  </v-card-text>
</v-card>
</template>

<script>
import colorMxn from '@/mixins/colorMxn';
import timezoneMxn from '@/mixins/timezone_mixin';

export default {
  name: 'widthrawList',
  mixins: [colorMxn, timezoneMxn],
  data() {
    return{
       headers: [
        { text: "ID", value: "id" },
        { text: "Account", value: "user_payment_method" },
        { text: "Amount", value: "request_amount" },
        { text: "Fee", value: "fees" },
        { text: 'State', value: 'state'},
        { text: 'Date', value: 'created'}
      ],
      transactions: [
        {
          id: 110277,
          created: "2021-04-29T16:36:32.037527Z",
          modified: "2021-04-30T19:06:25.544872Z",
          state: "Done",
          prev_state: "Processing",
          user_payment_method: {
            "title": "Payoneer",
            "info": "mordecaionwongah@gmail.com"
          },
          fees: 0,
          request_amount: 8768,
        },
        {
          id: 110278,
          created: "2021-04-29T16:36:32.037527Z",
          modified: "2021-04-30T19:06:25.544872Z",
          state: "Closed",
          prev_state: "Processing",
          user_payment_method: {
            "title": "Payoneer",
            "info": "mordecaionwongah@gmail.com"
          },
          fees: 0,
          request_amount: 8768,
        },
        {
          id: 110279,
          created: "2021-04-29T16:36:32.037527Z",
          modified: "2021-04-30T19:06:25.544872Z",
          state: "Closed",
          prev_state: "Processing",
          user_payment_method: {
            "title": "Payoneer",
            "info": "mordecaionwongah@gmail.com"
          },
          fees: 0,
          request_amount: 8768,
        },
        {
          id: 110280,
          created: "2021-04-29T16:36:32.037527Z",
          modified: "2021-04-30T19:06:25.544872Z",
          state: "Done",
          prev_state: "Processing",
          user_payment_method: {
            title: "Payoneer",
            info: "mordecaionwongah@gmail.com"
          },
          fees: 0,
          request_amount: 8768,
        },
      ]
    }
  }
}
</script>

<style>
.essay-green {
  color:#038c43;
}

tbody tr {
  border-bottom: none;
}
</style>