var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"400px","color":"#273142"}},[_c('v-card-title',{staticClass:"card-header"},[_c('h3',[_vm._v("Widthrawal Request")])]),_c('v-card-text',{staticClass:"pa-0",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"transparent",attrs:{"headers":_vm.headers,"items":_vm.transactions,"items-per-page":5,"color":_vm.ant},scopedSlots:_vm._u([{key:"item.user_payment_method",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(item.user_payment_method.title)+" ")]),_c('span',[_vm._v(_vm._s(item.user_payment_method.info))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.state==='Done')?_c('v-chip',{attrs:{"color":("" + _vm.essayGreen),"dark":"","label":"","small":""}},[_vm._v(_vm._s(item.state))]):_vm._e(),(item.state==='Closed')?_c('v-chip',{attrs:{"dark":"","color":("" + _vm.btnFab),"label":"","small":""}},[_vm._v(_vm._s(item.state))]):_vm._e()]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.determineDuration(item.created)))])]}},{key:"item.fees",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("$"+_vm._s(item.fees))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }